import { AppStateConstant } from "@crema/constants/AppState";
import { isNullOrEmpty } from "@crema/helpers/Common";
import axios from "axios";
import { reject } from "lodash";
const axiosInstance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export const setupInterceptor = () => {
  return new Promise((resolve) => {
    axiosInstance.interceptors.request.use(
      (config) => {
        const severToken = localStorage.getItem(AppStateConstant.ServerToken);
        const clientToken = sessionStorage.getItem(
          AppStateConstant.ClientToken,
        );

        if (
          !isNullOrEmpty(severToken) &&
          isNullOrEmpty(config?.header?.Authorization)
        ) {
          config.headers["Authorization"] = `Bearer ${severToken}`;
        }
        if (
          !isNullOrEmpty(clientToken) &&
          window.location.href?.includes("popout=true") &&
          !config.url.includes("/initiateConversation") &&
          !window.location.href?.includes("/signin")
        ) {
          config.headers["Authorization"] = `Bearer ${clientToken}`;
        }
        if (config.url.includes("/api/authenticate")) {
          delete config.headers["Authorization"];
        }
        resolve();
        return config;
      },
      (error) => {
        reject();
        return error;
      },
    );
    resolve();
  });
};

export const interceptUnauthorizedRequest = (logout) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem(AppStateConstant.ServerToken);
        localStorage.removeItem(AppStateConstant.userName);
        localStorage.removeItem(AppStateConstant.appState);
        logout();
      }
      return Promise.reject(error);
    },
  );
};
export default axiosInstance;
