import React from "react";
import PropTypes from "prop-types";
// import {useSidebarContext} from '@crema/context/AppContextProvider/SidebarContextProvider';
import { StyledAppLogo } from "./index.styled";

const AppLogo = () => {
  // const {sidebarColorSet} = useSidebarContext();
  return (
    <StyledAppLogo>
      <img src="/assets/images/app-icon.svg" alt="xBot" />
    </StyledAppLogo>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
