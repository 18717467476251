export const HttpStatusEnum = Object.freeze({
    "BAD_REQUEST": 400,
    "UNAUTHORIZED": 401,
    "FORBIDDEN": 403,
    "NOT_FOUND": 404,
    "METHOD_NOT_ALLOWED": 405,
    "CONFLICT": 409,

    "INTERNAL_SERVER_ERROR": 500,
    "NOT_IMPLEMENTED": 501,
    "BAD_GATEWAY": 502,
    "SERVICE_UNAVAILABLE": 503,
    "GATEWAY_TIMEOUT": 504,
});

export const HTTPStatusCodeEnum = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    NOT_MODIFIED: 304,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
};

export const HTTPStatusCodeMessage = {
    [HTTPStatusCodeEnum.OK]: "OK",
    [HTTPStatusCodeEnum.CREATED]: "Created",
    [HTTPStatusCodeEnum.ACCEPTED]: "Accepted",
    [HTTPStatusCodeEnum.NO_CONTENT]: "No Content",
    [HTTPStatusCodeEnum.MOVED_PERMANENTLY]: "Moved Permanently",
    [HTTPStatusCodeEnum.FOUND]: "Found",
    [HTTPStatusCodeEnum.NOT_MODIFIED]: "Not Modified",
    [HTTPStatusCodeEnum.BAD_REQUEST]: "Bad Request",
    [HTTPStatusCodeEnum.UNAUTHORIZED]: "Unauthorized",
    [HTTPStatusCodeEnum.FORBIDDEN]: "Forbidden",
    [HTTPStatusCodeEnum.NOT_FOUND]: "Not Found",
    [HTTPStatusCodeEnum.METHOD_NOT_ALLOWED]: "Method Not Allowed",
    [HTTPStatusCodeEnum.REQUEST_TIMEOUT]: "Request Timeout",
    [HTTPStatusCodeEnum.INTERNAL_SERVER_ERROR]: "Internal Server Error",
    [HTTPStatusCodeEnum.NOT_IMPLEMENTED]: "Not Implemented",
    [HTTPStatusCodeEnum.BAD_GATEWAY]: "Bad Gateway",
    [HTTPStatusCodeEnum.SERVICE_UNAVAILABLE]: "Service Unavailable",
    [HTTPStatusCodeEnum.GATEWAY_TIMEOUT]: "Gateway Timeout",
}; 