import React from "react";
import Account from "../../../modules/account/MyProfile";
import { RoutePermittedRole } from "@crema/constants/AppConst";

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/my-profile",
    element: <Account />,
  },
];
