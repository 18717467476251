import React, { useState } from "react";

import { HiUser } from "react-icons/hi";
import { AiFillLock } from "react-icons/ai";
import IntlMessages from "@crema/helpers/IntlMessages";
import {
  StyledUserProfileContainer,
  StyledUserProfileTabs,
} from "./index.styled";
import AppAnimate from "@crema/components/AppAnimate";
import { infoViewContext } from "@crema/constants/AppConst";
import {
  ChangePassword,
  PersonalInfo,
} from "@crema/modules/profile/UserProfile";
import HeaderPofile from "./header";
import { useAuthUser, useAuthMethod } from "@crema/hooks/AuthHooks";
import { Form } from "antd";
import AppLoader from "@crema/components/AppLoader";
import {
  updateUserProfile,
  updateUserPhoto,
} from "@crema/api-services/UserApi";
import { message } from "antd";
import { HTTPStatusCodeMessage } from "@crema/enum/httpStatus.enum";
const items = ({ personalInfo }) => [
  {
    label: (
      <span className="user-profile-icon">
        <HiUser className="icon" />
        <span>
          <IntlMessages id="common.profile" />
        </span>
      </span>
    ),
    key: "profile",
    children: (
      <PersonalInfo
        form={personalInfo?.form}
        user={personalInfo?.user}
        onPhotoChange={personalInfo?.onPhotoChange}
        onFormSubmit={personalInfo?.onFormSubmit}
      />
    ),
  }, // remember to pass the key prop
  {
    label: (
      <span className="user-profile-icon">
        <AiFillLock className="icon" />
        <span>
          <IntlMessages id="common.changePassword" />
        </span>
      </span>
    ),
    key: "changePassword",
    children: <ChangePassword />,
  },
];
const UserProfile = () => {
  const infoViewActionsContext = infoViewContext();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthUser();
  const { reFetchUser, updateUserInfo } = useAuthMethod();
  const [form] = Form.useForm();
  const onFormSubmit = () => {
    if (!form?.isFieldsTouched()) {
      return;
    }
    setIsLoading(true);
    form
      ?.validateFields()
      .then((values) => {
        updateUserProfile(
          {
            phoneNumber: values?.phoneNumber,
            fullName: values?.fullName,
            contactEmail: values?.contactEmail,
            userId: user?.moreInfo?.userId,
          },
          infoViewActionsContext,
        )
          ?.then(() => {
            reFetchUser();
          })
          ?.finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(
          HTTPStatusCodeMessage[error?.response?.status] || "Error",
        );
      });
  };

  const onPhotoChange = (fileChange, photoUrl) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("cmd", JSON.stringify({ userId: user?.moreInfo?.userId }));
    formData.append("file", fileChange);
    updateUserPhoto(formData, infoViewActionsContext)
      ?.then(() => {})
      ?.finally(() => {
        setIsLoading(false);
        if (photoUrl) {
          updateUserInfo({
            moreInfo: {
              ...user?.moreInfo,
              photoURL: photoUrl,
            },
          });
        }
      })
      .catch((error) => {
        message.error(
          HTTPStatusCodeMessage[error?.response?.status] || "Error",
        );
        reFetchUser();
      });
  };

  return (
    <>
      {isLoading ? <AppLoader /> : <></>}
      <StyledUserProfileContainer>
        <HeaderPofile></HeaderPofile>
        <AppAnimate animation="transition.slideUpIn" delay={200}>
          <StyledUserProfileTabs
            key="profile"
            destroyInactiveTabPane
            defaultActiveKey="profile"
            tabPosition="left"
            items={items({
              personalInfo: { form, user, onFormSubmit, onPhotoChange },
            })}
          />
        </AppAnimate>
      </StyledUserProfileContainer>
    </>
  );
};

export default UserProfile;
