import { APP_CONFIG } from "./AppConfig";
export const AppStateConstant = {
  userName: "username" + APP_CONFIG.APP_NAME,
  tenant: "tenant" + APP_CONFIG.APP_NAME,
  appState: APP_CONFIG.APP_CODE + "_APP_STATE",
  JWT: "JWT_" + APP_CONFIG.APP_NAME,
  USER_AWS: "USER_AWS_" + APP_CONFIG.APP_NAME,
  Language: "language",
  AccessToken: APP_CONFIG.APP_CODE + "_accessToken",
  ClientToken: APP_CONFIG.APP_CODE + "_clientToken",
  BoldBiConfigKey: APP_CONFIG.APP_CODE + "_boldBiConfig",
  IdToken: APP_CONFIG.APP_CODE + "_idToken",
  RefreshToken: APP_CONFIG.APP_CODE + "_refreshToken",
  ServerToken: APP_CONFIG.APP_CODE + "_serverToken",
  X_AWS_Authorization: APP_CONFIG.APP_CODE + "X-AWS-Authorization",
  TIME_LOGIN: "TIME_LOGIN",
  typeSignin: "ISIGN_TYPE_SIGNIN",
  SIGNIN_APP: "SIGNIN_APP",
  SIGNIN_SAML: "SIGNIN_SAML",
  SIGNIN_SAML_AUTO: "SIGNIN_SAML_AUTO",
  SIGNIN_SAML_STOP: "SIGNIN_SAML_STOP",
  SIGNIN_SAML_GO: "SIGNIN_SAML_GO",
};
