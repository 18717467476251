/* eslint-disable no-unused-vars */
import React, { createContext, useState, useContext, useReducer } from "react";

import { BrowserRouter } from "react-router-dom";
import AppContextProvider from "@crema/context/AppContextProvider";
import AppThemeProvider from "@crema/context/AppThemeProvider";
import AppLocaleProvider from "@crema/context/AppLocaleProvider";
import AppAuthProvider from "@crema/core/AppAuthProvider";
import AuthRoutes from "@crema/components/AuthRoutes";
import AppLayout from "@crema/core/AppLayout";
import "@crema/mockapi";
import { GlobalStyles } from "@crema/core/theme/GlobalStyle";
import { Normalize } from "styled-normalize";
import "./styles/index.css";

export const SocketContext = createContext();
export const useSocketContext = () => useContext(SocketContext);
export const SeverTokenContext = createContext();
export const useServerToken = () => useContext(SeverTokenContext);
const App = () => {
  return (
    <AppContextProvider>
      <AppThemeProvider>
        <AppLocaleProvider>
          <AppAuthProvider>
            <BrowserRouter>
              <AuthRoutes>
                <GlobalStyles />
                <Normalize />
                <AppLayout />
              </AuthRoutes>
            </BrowserRouter>
          </AppAuthProvider>
        </AppLocaleProvider>
      </AppThemeProvider>
    </AppContextProvider>
  );
};

export default App;
