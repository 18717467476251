import React from "react";
import PropTypes from "prop-types";
import { StyledAppHeader } from "./index.styled";
import AppLanguageSwitcher from "@crema/components/AppLanguageSwitcher";
const AppHeader = ({ children }) => {
  return (
    <StyledAppHeader>
      {children}
      <AppLanguageSwitcher />
    </StyledAppHeader>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  children: PropTypes.node,
};
