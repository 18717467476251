/* eslint-disable no-unused-vars */
import React from "react";
import { CgBot } from "react-icons/cg";
import { RiGroupLine } from "react-icons/ri";
import { RiPencilRuler2Line } from "react-icons/ri";
import { GrAnalytics } from "react-icons/gr";
import { MdOutlineSupportAgent, MdOutlineAnalytics } from "react-icons/md";
import { UserRoleEnum } from "@crema/constants/AppEnums";
import { BiConversation, BiSupport } from "react-icons/bi";
import { TbSettingsAutomation } from "react-icons/tb";
const routesConfig = [
  {
    id: "conversations",
    title: "Conversations",
    permittedRole: UserRoleEnum.AGENT,
    icon: <BiConversation />,
    messageId: "sidebar.apps.conversations",
    path: "/apps/conversations",
  },

  {
    id: "groups",
    title: "Group",
    permittedRole: UserRoleEnum.MANAGER,
    messageId: "sidebar.apps.groups",
    icon: <RiGroupLine />,
    path: "/apps/groups",
  },

  {
    id: "ai-agents",
    title: "AI Agents",
    permittedRole: UserRoleEnum.ADMIN,
    messageId: "sidebar.apps.ai_bots",
    icon: <BiSupport />,
    path: "/apps/ai-agents",
  },
  {
    id: "agents",
    title: "Agents",
    permittedRole: UserRoleEnum.ADMIN,
    messageId: "sidebar.apps.users",
    icon: <MdOutlineSupportAgent />,
    path: "/apps/agents",
  },

  {
    id: "rules",
    title: "Rules",
    permittedRole: UserRoleEnum.ADMIN,
    messageId: "sidebar.apps.rules",
    icon: <RiPencilRuler2Line />,
    path: "/apps/rules",
  },
  {
    id: "bots",
    title: "Bots",
    permittedRole: UserRoleEnum.ADMIN,
    messageId: "sidebar.apps.bots",
    icon: <CgBot />,
    path: "apps/bots",
  },

  {
    id: "ai-flows-management",
    key: "ai-flows-management",
    title: "AI Flows Management",
    permittedRole: UserRoleEnum.ADMIN,
    messageId: "sidebar.apps.ai_agent_management",
    icon: <TbSettingsAutomation size={20} />,
    children: [
      {
        id: "ai-flows",
        title: "AI Flows",
        permittedRole: UserRoleEnum.ADMIN,
        messageId: "sidebar.apps.ai_flows_overview",
        path: "/apps/ai-flows",
      },
      {
        id: "function-defs",
        title: "Functions",
        permittedRole: UserRoleEnum.ADMIN,
        messageId: "sidebar.apps.functionDefs",
        path: "apps/function-defs",
      },
      {
        id: "knowledge-base",
        title: "Knowledge Base",
        permittedRole: UserRoleEnum.ADMIN,
        messageId: "sidebar.apps.knowledgeBase",
        path: "apps/knowledge-base",
      },
    ],
  },
  {
    id: "dashboard",
    key: "dashboard",
    title: "Dashboard",
    permittedRole: UserRoleEnum.ADMIN,
    messageId: "sidebar.apps.dashboardsAndReports",
    icon: <MdOutlineAnalytics />,
    children: [
      {
        id: "messages-log",
        title: "Messages Log",
        permittedRole: UserRoleEnum.ADMIN,
        messageId: "sidebar.apps.messagesLog",
        path: "/apps/messages-log",
      },
      {
        id: "dashboards",
        title: "Dashboards",
        permittedRole: UserRoleEnum.ADMIN,
        messageId: "sidebar.apps.dashboards",
        path: "/apps/dashboards",
      },
      {
        id: "dashboard-designer",
        title: "Design Dashboard",
        permittedRole: UserRoleEnum.ADMIN,
        messageId: "sidebar.apps.designDashboard",
        path: "/apps/dashboard-designer",
      },
    ],
  },
];
export default routesConfig;
