import React from "react";
import PropTypes from "prop-types";
import PropsTypes from "prop-types";
import AppVerticalMenu from "../components/AppVerticalNav";
import {
  StyledAppMainSidebar,
  StyledAppSidebarScrollbar,
} from "./index.styled";
import UserInfo from "../components/UserInfo";

const AppSidebar = ({ routesConfig, isCollapsed }) => {
  return (
    <StyledAppMainSidebar
      collapsible
      breakpoint="md"
      collapsedWidth="0"
      collapsed={isCollapsed}
    >
      <UserInfo hasColor />
      <StyledAppSidebarScrollbar scrollToTop={false}>
        <AppVerticalMenu routesConfig={routesConfig} />
      </StyledAppSidebarScrollbar>
    </StyledAppMainSidebar>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  routesConfig: PropsTypes.array.isRequired,
  isCollapsed: PropTypes.bool,
};
