import { postDataApi, getDataApi } from "@crema/hooks/APIHooks";
import { APP_CONFIG, LOGIN_URL } from "@crema/constants/AppConfig";
export const signInApi = async (username, password) => {
  const url = LOGIN_URL.LOGIN;
  const loginObject = {
    username: username,
    password: password,
    appCode: APP_CONFIG.APP_CODE,
    tenantCode: "A4B",
    rememberMe: false,
  };
  return await postDataApi(url, {}, loginObject, true);
};

export const getUserInfo = () => {
  const url = LOGIN_URL.USER_INFO;
  return getDataApi(url, {}, {}, true, {});
};
