import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Dropdown } from "antd";
import { FaChevronDown } from "react-icons/fa";
import { useThemeContext } from "@crema/context/AppContextProvider/ThemeContextProvider";
import { useAuthMethod, useAuthUser } from "@crema/hooks/AuthHooks";
import { useSidebarContext } from "@crema/context/AppContextProvider/SidebarContextProvider";
import PropTypes from "prop-types";
import { roleName, USER_DEFAULT_AVATAR_URL } from "@crema/constants/AppConst";
import {
  StyledCrUserDesignation,
  StyledCrUserInfo,
  StyledCrUserInfoAvatar,
  StyledCrUserInfoContent,
  StyledCrUserInfoInner,
  StyledUserArrow,
  StyledUsername,
  StyledUsernameInfo,
} from "./index.styled";
import { UserRoleEnum } from "@crema/constants/AppEnums";
import { useIntl } from "react-intl";

const UserInfo = ({ hasColor }) => {
  const { themeMode } = useThemeContext();
  const { logout } = useAuthMethod();
  const { user } = useAuthUser();
  const { messages } = useIntl();
  const navigate = useNavigate();
  const { sidebarColorSet } = useSidebarContext();
  const { allowSidebarBgImage } = useSidebarContext();

  const getUserAvatar = () => {
    if (user?.displayName) {
      return user?.displayName?.charAt(0)?.toUpperCase();
    }
    if (user?.email) {
      return user?.email.charAt(0)?.toUpperCase();
    }
  };

  const items = [
    {
      key: 1,
      label: (
        <div onClick={() => navigate("/my-profile")}>
          {messages["common.profile"]}
        </div>
      ),
    },
    {
      key: 2,
      label: <div onClick={() => logout()}>{messages["common.logout"]}</div>,
    },
  ];

  const getRollName = useCallback(
    () =>
      roleName[user?.authorities[0]?.authority] || roleName[UserRoleEnum.AGENT],
    [user],
  );

  const renderUserName = useCallback(() => {
    return (
      <>
        <StyledUsernameInfo>
          <StyledUsername
            className={clsx("text-truncate", {
              light: themeMode === "light",
            })}
          >
            {user?.displayName ?? user?.fullName}
          </StyledUsername>
          <StyledUserArrow className="cr-user-arrow">
            <FaChevronDown />
          </StyledUserArrow>
        </StyledUsernameInfo>
      </>
    );
  }, [user]);

  return (
    <>
      {hasColor ? (
        <StyledCrUserInfo
          style={{
            backgroundColor: allowSidebarBgImage
              ? ""
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx("cr-user-info", {
            light: themeMode === "light",
          })}
        >
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}
          >
            <StyledCrUserInfoInner className="ant-dropdown-link">
              <StyledCrUserInfoAvatar
                src={user?.moreInfo?.photoURL || USER_DEFAULT_AVATAR_URL}
              />
              <StyledCrUserInfoContent className="cr-user-info-content">
                {renderUserName()}
                <StyledCrUserDesignation className="text-truncate">
                  {getRollName()}
                </StyledCrUserDesignation>
              </StyledCrUserInfoContent>
            </StyledCrUserInfoInner>
          </Dropdown>
        </StyledCrUserInfo>
      ) : (
        <StyledCrUserInfo
          className={clsx("cr-user-info", {
            light: themeMode === "light",
          })}
        >
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}
          >
            <StyledCrUserInfoInner className="ant-dropdown-link">
              {user?.moreInfo?.photoURL ? (
                <StyledCrUserInfoAvatar src={user?.moreInfo?.photoURL} />
              ) : (
                <StyledCrUserInfoAvatar>
                  {getUserAvatar()}
                </StyledCrUserInfoAvatar>
              )}
              <StyledCrUserInfoContent className="cr-user-info-content">
                <StyledUsernameInfo>
                  <StyledUsername
                    className={clsx("text-truncate", {
                      light: themeMode === "light",
                    })}
                  >
                    {user?.displayName ? user?.displayName : "admin user "}
                  </StyledUsername>
                  <StyledUserArrow className="cr-user-arrow">
                    <FaChevronDown />
                  </StyledUserArrow>
                </StyledUsernameInfo>
                <StyledCrUserDesignation className="text-truncate cr-user-designation">
                  {getRollName()}
                </StyledCrUserDesignation>
              </StyledCrUserInfoContent>
            </StyledCrUserInfoInner>
          </Dropdown>
        </StyledCrUserInfo>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
