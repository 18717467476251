import styled from "styled-components";
import { Layout } from "antd";

const { Content } = Layout;

export const StyledMainContentView = styled(Content)`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${"" /*padding: 10px 10px 0;*/}
  min-height: auto !important;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    ${"" /*padding: 10px 10px 0;*/}
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;
