class NameConfig {
  static get HOST_NAME() {
    return process.env.REACT_APP_HOST_NAME;
  }

  static get HOST_NAME_LOGIN() {
    return process.env.REACT_APP_HOST_NAME_LOGIN;
  }
}
export const APP_CONFIG = {
  DEFAULT_DOWNLOAD_FILE_NAME: "download_file",
  LIMIT_QUERY: 20,
  MESSAGE_LIMIT_QUERY: 75,
  TABLE_PAGE_SIZE: 13,
  TOKEN_EXPIRED_INTERVAL: 60000 * 10, // 10 minutes
  QUERY_TIME_OUT: 30000,
  COMMAND_TIME_OUT: 30000,
  MAX_ELEMENT_IN_LIST: 1000,
  VERSION: process.env.REACT_APP_VERSION_APP,
  VERSION_HELP: "1.0",
  APP_CODE: "XCHATBOT",
  APP_NAME: "xBot",
  TENANT: "A4B",
  COMPANY_CODE: "A4B",
  COMPANY_NAME: "A4B",
  GROW_HEIGHT_40: 40,
  GROW_HEIGHT_44: 44,
  GROW_HEIGHT_50: 50,
  HOST_NAME: NameConfig.PATH_SERVICE,
  HOST_NAME_LOGIN: NameConfig.PATH_SERVICE_LOGIN,
  HOST_NAME_GW: NameConfig.PATH_SERVICE_GW,
  KEY_JWT: "Bearer ",
  INPUT_TEXT_DEBOUNCE_TIME: 400,
  SCROLL_DEBOUNCE_TIME: 400,
  LANGUAGES: [
    {
      name: "Vietnam",
      code: "vi",
      locale: "vi",
    },
    {
      name: "English",
      code: "en",
      locale: "en-US",
    },
  ],
  LANGUAGE_LOCALE: {
    vi: "vi",
    en: "en-US",
  },
  DEFAULT_LANGUAGE: "vi",
  LANGUAGE_WEB: "xBot_LANGUAGE_WEB",
  MCR_XPCBR: NameConfig.HOST_NAME + "services/xpcbr/api/",
  MCR_XPDBRP: NameConfig.HOST_NAME + "services/xpdbrp/api/",
  MCR_XPSECMT: NameConfig.HOST_NAME + "services/xpsecmt/api/",
  MCR_XPDMS: NameConfig.HOST_NAME + "services/xpdms/api/",
  MCR_XPOUA: NameConfig.HOST_NAME + "services/xpoua/api/",
  MCR_XBOLDBI_V1: NameConfig.HOST_NAME + "services/xpboldbi/api/v1/",
  XPBOT: NameConfig.HOST_NAME + "services/xpbot/api/",
};
export const LOGIN_URL = {
  LOGIN: NameConfig.HOST_NAME_LOGIN + "api/authenticate",
  USER_INFO: NameConfig.HOST_NAME_LOGIN + "api/profileInfo",
  UPLOAD_PROFILE_PHOTO: APP_CONFIG.MCR_XPSECMT + "user/profile/avatar/upload",
  DOWNLOAD_PROFILE_PHOTO:
    APP_CONFIG.MCR_XPSECMT + "user/profile/avatar/download",
  DOWNLOAD_PROFILE_PHOTO_BY_USER_ID:
    APP_CONFIG.MCR_XPSECMT + "user/profile/avatar/downloadByUser",
};
