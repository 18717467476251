import React, { useState } from "react";
import { Button, Col, Form, Input, message } from "antd";
import AppRowContainer from "@crema/components/AppRowContainer";
import IntlMessages from "@crema/helpers/IntlMessages";
import {
  StyledUserProfileForm,
  StyledUserProfileFormTitle,
  StyledUserProfileGroupBtn,
} from "../index.styled";
import { useIntl } from "react-intl";
import AppPageMeta from "@crema/components/AppPageMeta";
import { changePassword } from "@crema/api-services/UserApi";
import { catchError, delay, finalize, from, tap, throwError } from "rxjs";
import { useForm } from "antd/es/form/Form";
import { useInfoViewActionsContext } from "@crema/context/AppContextProvider/InfoViewContextProvider";
import { useAuthMethod } from "@crema/hooks/AuthHooks";

const ChangePassword = () => {
  const { messages } = useIntl();
  const [form] = useForm();
  const { logout } = useAuthMethod();
  const [formPending, setFormPending] = useState(false);
  const infoViewActionsContext = useInfoViewActionsContext();
  const onFinish = (values) => {
    setFormPending(true);
    from(
      changePassword(
        { oldPwd: values?.oldPassword, newPwd: values?.password },
        infoViewActionsContext,
      ),
    )
      .pipe(
        catchError((err) => {
          message.error(messages["common.somethingWentWrong"]);
          return throwError(() => new Error(err));
        }),
        tap(() => {
          message.success(messages["common.updateSuccessfully"]);
          form?.resetFields();
        }),
        delay(1000),
        finalize(() => {
          setFormPending(false);
        }),
      )
      .subscribe(() => {
        logout();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <AppPageMeta title={messages["common.changePassword"]} />
      <StyledUserProfileForm
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <StyledUserProfileFormTitle>
          <IntlMessages id="common.changePassword" />
        </StyledUserProfileFormTitle>
        <AppRowContainer gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="oldPassword"
              rules={[
                { required: true, message: messages["validation.required"] },
              ]}
            >
              <Input.Password placeholder={messages["common.oldPassword"]} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} />
          <Col xs={24} md={12}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: messages["validation.required"] },
              ]}
            >
              <Input.Password placeholder={messages["common.newPassword"]} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: messages["validation.required"],
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      messages["validation.confirmPasswordNotMatch"],
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={messages["common.confirmPassword"]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <StyledUserProfileGroupBtn
              shouldUpdate
              className="user-profile-group-btn"
            >
              <Button
                type="primary"
                loading={formPending}
                htmlType="submit"
                style={{ marginRight: "6px" }}
              >
                {messages["common.save"]}
              </Button>
              <Button htmlType="cancel">{messages["common.cancel"]}</Button>
            </StyledUserProfileGroupBtn>
          </Col>
        </AppRowContainer>
      </StyledUserProfileForm>
    </>
  );
};

export default ChangePassword;
