import React from "react";
import { RoutePermittedRole } from "@crema/constants/AppConst";

const Signin = React.lazy(() =>
  import("../../modules/userPages/UserPages/Signin"),
);
const Signup = React.lazy(() =>
  import("../../modules/userPages/UserPages/Signup"),
);
const ForgetPassword = React.lazy(() =>
  import("../../modules/userPages/UserPages/ForgetPassword"),
);
const ResetPassword = React.lazy(() =>
  import("../../modules/userPages/UserPages/ResetPassword"),
);
const UnlockScreen = React.lazy(() =>
  import("../../modules/userPages/UserPages/UnlockScreen"),
);
const StyledSignin = React.lazy(() =>
  import("../../modules/userPages/StyledUserPages/Signin"),
);
const StyledSignup = React.lazy(() =>
  import("../../modules/userPages/StyledUserPages/Signup"),
);
const StyledForgetPassword = React.lazy(() =>
  import("../../modules/userPages/StyledUserPages/ForgetPassword"),
);
const StyledResetPassword = React.lazy(() =>
  import("../../modules/userPages/StyledUserPages/ResetPassword"),
);
const StyledUnlockScreen = React.lazy(() =>
  import("../../modules/userPages/StyledUserPages/UnlockScreen"),
);

export const userPagesConfig = [
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/sign-in-1",
    element: <Signin />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/sign-in-2",
    element: <StyledSignin />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/sign-up-1",
    element: <Signup />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/sign-up-2",
    element: <StyledSignup />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/forgot-password-1",
    element: <ForgetPassword />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/forgot-password-2",
    element: <StyledForgetPassword />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/reset-password-1",
    element: <ResetPassword />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/reset-password-2",
    element: <StyledResetPassword />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/lock-1",
    element: <UnlockScreen />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: "/extra-pages/user/lock-2",
    element: <StyledUnlockScreen />,
  },
];
