import {
  useDefaultAuthContext,
  useAuthActionContext,
} from "@crema/services/auth/default-auth/AuthProvider";

export const useAuthUser = () => {
  const { isLoading, isAuthenticated, user } = useDefaultAuthContext();
  return {
    isLoading,
    isAuthenticated,
    user,
  };
};

export const useAuthMethod = () => {
  const { signIn, logout, reFetchUser, updateUserInfo } =
    useAuthActionContext();

  return {
    signIn,
    logout,
    reFetchUser,
    updateUserInfo,
  };
};
