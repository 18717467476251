/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-useless-escape */
import { useIntl } from "react-intl";
import React, { useCallback } from "react";
import { formatDistanceToNow, format, differenceInDays } from "date-fns";
import { downloadUserPhotoResponseTypeBlob } from "@crema/api-services/UserApi";
import {
  IMAGE_MIME_TYPES,
  MESSAGE_EXPIRED_TIME,
  RoleLevel,
  TIME_UNITS_BY_LOCALE,
  USER_DEFAULT_AVATAR_URL,
} from "@crema/constants/AppConst";
import { v4 as uuidv4 } from "uuid";
import { APP_CONFIG } from "@crema/constants/AppConfig";
import { Observable } from "rxjs";

export const hasPermission = (permissions, checkedPermission) => {
  return permissions?.some(
    (permission) => permission?.target === checkedPermission
  );
};

export const mapVersionNumber = (version) => {
  const versionParts = version.split(".");
  while (versionParts.length < 3) {
    versionParts.push("0");
  }
  return versionParts.join(".");
};

export const generateEmptyModelFromData = (data) => {
  if (Array.isArray(data) && data.length > 0) {
    // Get the first item and use it as the model template
    const modelTemplate = data[0];
    return JSON.parse(JSON.stringify(modelTemplate), (key) => {
      return { [key]: null };
    });
  }
  return null;
};

export const generatePagedArray = (
  totalCount,
  pageIndex,
  pageSize,
  pageData
) => {
  // Generate the empty model from the first item in the pageData
  const emptyModel = generateEmptyModelFromData(pageData);
  // Create an array with the total count filled with the empty model
  const result = new Array(totalCount).fill(null).map(() => emptyModel);

  // Calculate the starting index of the page
  const startIndex = pageIndex * pageSize;

  // Place the page data into the appropriate index positions
  pageData.forEach((item, i) => {
    const dataIndex = startIndex + i;
    if (dataIndex < totalCount) {
      result[dataIndex] = item;
    }
  });

  return result;
};

export const slideTitle = (title) => {
  title = title.substring(1) + title.charAt(0);
  document.title = title;
};

export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === "number") return valueSet;
  switch (breakpoint) {
    case "xs":
      return valueSet.xs;
    case "sm":
      return valueSet.sm || valueSet.xs;
    case "md":
      return valueSet.md || valueSet.sm || valueSet.xs;
    case "lg":
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const isMarkdown = (inputString) => {
  // Regular expressions for detecting Markdown patterns
  const markdownPatterns = [
    /(?:^|\s)([*_]{1,3})(\S.*?\S)\1(?:\s|$)/, // Bold or italic
    /(?:^|\s)```[\s\S]+?```(?:\s|$)/, // Code block
    /(?:^|\s)>[\s\S]+(?:\n|$)/, // Blockquote
    /(?:^|\s)(#{1,6})\s+[\S]+/, // Headers
    /(?:^|\s)[-+*]\s+[\S]+/, // Unordered list
    /(?:^|\s)\d+\.\s+[\S]+/, // Ordered list
    /\[.*?\]\(.*?\)/, // Links
    /`[^`\n]+`/, // Inline code
    /^(?:[-*]\s?){3,}$/, // Horizontal rule
    /!\[.*?\]\(.*?\)/, // Image
    /<\/?[a-z][\s\S]*>/i, // HTML tags
    /\|[\s\S]*\|/, // Tables
    /~~[^~]+~~/, // Strikethrough
    /\*\*[^*\n]+\*\*/, // Bold with **
    /__[^\n]+__/, // Bold with __
    /\*[^\n]+\*/, // Italic with *
    /_[^\n]+_/, // Italic with _
    /\[.+\]:\s*.+/, // Footnotes and references
    /(\+\+)[^\n]+\1/, // Inserted text
    /(==)[^\n]+\1/, // Marked text
    /:([a-z_]+):/, // Emoji
    /\^\[[^\]]+\]/, // Inline footnotes
    /(^|[^\\])~[^\n]+~($|[^\\])/, // Subscript
    /(^|[^\\])\^[^\n]+\^($|[^\\])/, // Superscript
    /^:::\s*(\w+)[\s\S]*?:::/, // Custom containers
    /^(\d+)\.\s.*$/, // Ordered list
    /^(\+|\-|\*)\s.*$/, // Unordered list
  ];
  return markdownPatterns.some((pattern) => pattern.test(inputString));
};
export const downloadFile = (link, filename) => {
  // Create an anchor element
  const anchor = document.createElement("a");

  // Set the href attribute to the link
  anchor.href = link;

  // Set the download attribute to specify the filename
  anchor.download = filename;

  // Append the anchor to the document body
  document.body.appendChild(anchor);

  // Programmatically click the anchor to trigger the download
  anchor.click();

  // Remove the anchor from the document body
  document.body.removeChild(anchor);
};

export const emitStringInChunks = (str) => {
  let remainingText = str;

  return new Observable((observer) => {
    const emitChunk = () => {
      if (remainingText.length > 0) {
        // Random length for each chunk (between 3 and 10 characters)
        const chunkLength = Math.floor(Math.random() * 8) + 3;
        const chunk = remainingText.slice(0, chunkLength);
        remainingText = remainingText.slice(chunkLength);

        // Emit the chunk with a faster random delay between 100ms and 300ms
        observer.next(chunk);
        setTimeout(emitChunk, Math.random() * 100 + 50);
      } else {
        observer.complete();
      }
    };

    emitChunk(); // Start emitting chunks
  });
};
export const decodeHtmlEntities = (str) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = str;
  return textarea.value;
};

export const isEmpty = (obj) => {
  for (let key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const getFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  let k = 1024,
    dm = 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const multiPropsFilter = (products, filters, stringKey = "title") => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (filters[key].length === 0) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle));
      }
      if (filters[key]?.start || filters[key]?.end) {
        if (key === "mrp") {
          return (
            product[key] >= filters[key].start &&
            product[key] < filters[key].end
          );
        } else {
          const start = new Date(filters[key].start).getTime();
          const end = new Date(filters[key].end).getTime();
          const productDate = new Date(product[key]).getTime();

          return productDate >= start && productDate <= end;
        }
      }
      if (key === stringKey) {
        return product[key]
          ?.toLowerCase()
          .includes(filters[key]?.toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

// 'intl' service singleton reference
let intl;

export function IntlGlobalProvider({ children }) {
  intl = useIntl();
  // Keep the 'intl' service reference
  return children;
}

export const appIntl = () => {
  return intl;
};

export const generateRandomUniqueNumber = () => {
  const numbers = [];
  const number = Math.floor(Math.random() * 100000000);

  if (numbers.includes(number)) {
    return generateRandomUniqueNumber();
  } else {
    return number;
  }
};

export const isNotNull = (data) => {
  if (data == null || data === undefined) {
    return false;
  }
  if (data === "") {
    return false;
  }
  return true;
};

export const getFirstLetter = (name) => {
  return name?.charAt(0)?.toUpperCase() || "N";
};
export const getChatUserName = (userName) => {
  return userName?.split("#")?.length > 1 ? userName?.split("#")[1] : userName;
};

export const getUserAvatar = (user) => {
  const name = user?.displayName || user?.name;
  if (name) {
    return name?.charAt(0)?.toUpperCase();
  }
  if (user?.email) {
    return user?.email?.charAt(0)?.toUpperCase();
  }
};

export const getRandomDate = (start, end) => {
  // Convert the start and end dates to timestamps
  const startTimestamp = start.getTime();
  const endTimestamp = end.getTime();

  // Generate a random timestamp between the start and end
  const randomTimestamp =
    startTimestamp + Math.random() * (endTimestamp - startTimestamp);

  // Convert the random timestamp back into a Date object
  return new Date(randomTimestamp);
};
export const formatDate = (date) => {
  let month = "" + (date.getMonth() + 1), // getMonth() is zero-based
    day = "" + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join(" "); // Assembling the date as "MM dd, yyyy"
};

export const formatDateMMMddYYYY = (date, locale) => {
  return date.toLocaleDateString(locale, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};
export const formatDateMMMddYYYYWithTime = (date, locale) => {
  return date.toLocaleDateString(locale, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};
export const formatTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let amOrPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutes} ${amOrPm}`;
};

export const getDefaultParams = () => ({
  startRow: 0,
  endRow: APP_CONFIG.LIMIT_QUERY,
  rowGroupCols: [],
  valueCols: [],
  pivotCols: [],
  pivotMode: false,
  groupKeys: [],
  filterModel: {},
  sortModel: [],
});

export const calculateScrollHeight = (headerHeight, footerHeight) => {
  return window.innerHeight - headerHeight - footerHeight;
};

export const generateEntityCode = (inputString) => {
  const uuid = uuidv4();
  // Combine the input string and the UUID to generate the entity code
  const entityCode = `${inputString}_${uuid}`;
  return entityCode;
};

// Grouping function that takes the fieldName dynamically
export const groupBy = (data, fieldName) => {
  const groupedData = [];
  let currentGroupValue = null;

  data.forEach((item) => {
    // Check if the field exists and has a value; if not, assign "UNKNOWN"
    const groupValue = item[fieldName] ?? "UNKNOWN";

    if (groupValue !== currentGroupValue) {
      groupedData.push({
        key: `group-${groupValue}`,
        [fieldName]: groupValue,
        isGroupHeader: true, // Custom flag for group header
      });
      currentGroupValue = groupValue;
    }
    groupedData.push(item); // Push the actual item
  });

  return groupedData;
};
export const isNullOrEmpty = (value) => {
  return (
    !value ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    value === ""
  );
};

export const beautifyJson = (jsonString) => {
  try {
    const jsonObject = JSON.parse(jsonString);
    return JSON.stringify(jsonObject, null, 4); // 2 is the number of spaces for indentation
  } catch (error) {
    // handle error
    return jsonString; // return the original string if it's not a valid JSON string
  }
};

export const getConversationName = (conversation) => {
  const appCode =
    conversation.userId?.split(".")?.length > 1
      ? conversation.userId?.split(".")[0] + " - "
      : "";
  let name =
    conversation.userId?.split("#")?.length > 1
      ? appCode + conversation.userId?.split("#")[1]
      : conversation.userId;
  if (conversation?.conversationId?.split("#")?.length > 1) {
    name = conversation?.conversationId;
  }
  return name;
};

export const getColorByChar = (char) => {
  const colors = [
    "#f94144",
    "#f3722c",
    "#f8961e",
    "#f9c74f",
    "#90be6d",
    "#43aa8b",
    "#577590",
    "#6d597a",
    "#b56576",
    "#e56b6f",
    "#eaac8b",
    "#d6a2e8",
    "#b8a9c9",
    "#6c8ead",
    "#5eaaa8",
    "#a3de83",
    "#7dd181",
    "#56cfe1",
    "#4ea8de",
    "#3f72af",
    "#112d4e",
    "#3c096c",
    "#6d435a",
    "#d00000",
    "#9d0208",
    "#6a040f",
    "#370617",
    "#03071e",
  ];

  const index = char?.toLowerCase()?.charCodeAt(0) - "a"?.charCodeAt(0);
  if (index >= 0 && index < 26) {
    return colors[index];
  }

  if (char >= "1" && char <= "9") {
    return colors[char - 1 + 26];
  }
  return "#000000"; // default color if char is not a letter or a number from 1 to 9
};

export const getImageObjectUrl = (binaryImage) => {
  const uint8Array = new Uint8Array(binaryImage.length);

  for (let i = 0; i < binaryImage.length; i++) {
    uint8Array[i] = binaryImage.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: "image/png" });
  return URL.createObjectURL(blob);
};

// Function to remove Markdown formatting
export const removeMarkdown = (content) => {
  return content
    .replace(/(?:^|\s)([*_]{1,3})(\S.*?\S)\1(?:\s|$)/g, "$2") // Bold or italic
    .replace(/(?:^|\s)```[\s\S]+?```(?:\s|$)/g, "") // Code block
    .replace(/(?:^|\s)>[\s\S]+(?:\n|$)/g, "") // Blockquote
    .replace(/(?:^|\s)(#{1,6})\s+[\S]+/g, "") // Headers
    .replace(/(?:^|\s)[-+*]\s+[\S]+/g, "") // Unordered list
    .replace(/(?:^|\s)\d+\.\s+[\S]+/g, "") // Ordered list
    .replace(/\[.*?\]\(.*?\)/g, "") // Links
    .replace(/`[^`\n]+`/g, "") // Inline code
    .replace(/^(?:[-*]\s?){3,}$/g, "") // Horizontal rule
    .replace(/!\[.*?\]\(.*?\)/g, "") // Image
    .replace(/<\/?[a-z][\s\S]*>/gi, "") // HTML tags
    .replace(/\|[\s\S]*\|/g, "") // Tables
    .replace(/~~[^~]+~~/g, "") // Strikethrough
    .replace(/\*\*[^*\n]+\*\*/g, "") // Bold with **
    .replace(/__[^\n]+__/g, "") // Bold with __
    .replace(/\*[^\n]+\*/g, "") // Italic with *
    .replace(/_[^\n]+_/g, "") // Italic with _
    .replace(/\[.+\]:\s*.+/g, "") // Footnotes and references
    .replace(/(\+\+)[^\n]+\1/g, "") // Inserted text
    .replace(/(==)[^\n]+\1/g, "") // Marked text
    .replace(/:([a-z_]+):/g, "") // Emoji
    .replace(/\^\[[^\]]+\]/g, "") // Inline footnotes
    .replace(/(^|[^\\])~[^\n]+~($|[^\\])/g, "") // Subscript
    .replace(/(^|[^\\])\^[^\n]+\^($|[^\\])/g, "") // Superscript
    .replace(/^:::\s*(\w+)[\s\S]*?:::/g, "") // Custom containers
    .replace(/^(\d+)\.\s.*$/g, "") // Ordered list
    .replace(/^(\+|\-|\*)\s.*$/g, ""); // Unordered list
};

export const getBase64Image = (binaryImage) => {
  if (!(binaryImage instanceof ArrayBuffer)) {
    throw new TypeError("input must be an instance of ArrayBuffer");
  }

  let binary = "";
  const bytes = new Uint8Array(binaryImage.length);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const getTimeGroup = (timestamp, i18nMessages) => {
  if (!timestamp) return "";

  // Use the native Date constructor to handle the timestamp
  const date = new Date(timestamp);
  const now = new Date();

  // Check if the date object is valid
  if (isNaN(date.getTime())) {
    return "";
  }

  // Calculate the difference in days between the provided timestamp and the current time
  const daysDifference = differenceInDays(now, date);

  // If the message was sent today, show the time elapsed
  if (daysDifference === 0) {
    const timeElapsed = formatDistanceToNow(date, { addSuffix: true });

    // Handle cases where the message was sent very recently
    if (timeElapsed.includes("less than a minute")) {
      return i18nMessages["message.justNow"];
    } else if (timeElapsed.includes("about a minute")) {
      return i18nMessages["message.lessThanAMinuteAgo"];
    }
    return timeElapsed;
  }

  // If the message was sent yesterday, return "Yesterday"
  if (daysDifference === 1) {
    const time = format(date, "hh:mm aa"); // Format the time as hh:mm AM/PM
    return `${i18nMessages["message.yesterday"]}, ${time}`; // Return "Yesterday" + Time
  }

  // If the message is older than 7 days, return the full date and time
  return format(date, "MMM dd, yyyy, hh:mm aa"); // Full date + time
};

export const getAvatarStyle = (name) => {
  if (name && name.includes("AI")) {
    return { backgroundColor: "blue" };
  }
  return {};
};

export const renderModalTitleFnc = (modalTitle, deps) => {
  return useCallback(() => {
    return (
      <div style={{ backgroundColor: "rgba(251,146,60,0.1)", padding: "20px" }}>
        <h1 style={{ margin: 0, fontSize: "1.25rem", fontWeight: "600" }}>
          {modalTitle?.current || modalTitle}
        </h1>
      </div>
    );
  }, [...deps]);
};

export function isHasPermission(permissions, checkedPermission) {
  return permissions?.some((permission) => permission === checkedPermission);
}

export function findDifferences(oldArray, newArray) {
  const removed = oldArray?.filter((value) => !newArray?.includes(value));
  return {
    added: newArray,
    removed,
  };
}
export function calculateDiffTime(startDate, endDate) {
  const diffInSeconds = Math.abs(startDate - endDate) / 1000;

  return {
    inSeconds: diffInSeconds,
  };
}

export function getTimeInSecondsDisplayLabel(timeInSeconds, locale) {
  const nf = new Intl.NumberFormat(locale);

  const units = TIME_UNITS_BY_LOCALE[locale] || TIME_UNITS_BY_LOCALE.en; // Default to English if locale is not supported

  if (timeInSeconds < 60) {
    return nf.format(timeInSeconds.toFixed(0)) + " " + units[0];
  } else if (timeInSeconds < 3600) {
    return nf.format((timeInSeconds / 60).toFixed(0)) + " " + units[1];
  } else if (timeInSeconds < 86400) {
    return nf.format((timeInSeconds / 3600).toFixed(0)) + " " + units[2];
  } else if (timeInSeconds < 2592000) {
    return nf.format((timeInSeconds / 86400).toFixed(0)) + " " + units[3];
  } else if (timeInSeconds < 31536000) {
    return nf.format((timeInSeconds / 2592000).toFixed(0)) + " " + units[4];
  } else {
    return nf.format((timeInSeconds / 31536000).toFixed(0)) + " " + units[5];
  }
}

export async function getImageByUserIds(users) {
  const result = new Map();
  await Promise.all(
    users?.map(async (user) => {
      if (user?.userId && user?.hasAvatar) {
        try {
          const avatar = await downloadUserPhotoResponseTypeBlob(user?.userId);
          result.set(user?.userId, avatar);
        } catch (error) {
          result.set(user?.userId, USER_DEFAULT_AVATAR_URL);
        }
      } else {
        result.set(user?.userId, USER_DEFAULT_AVATAR_URL);
      }
    })
  );
  return result;
}
export function quillDeltaToHtml(deltaOps) {
  let html = "";

  deltaOps.forEach((op) => {
    if (typeof op.insert === "string") {
      let text = op.insert;
      let attributes = op.attributes || {};

      if (attributes.bold) {
        text = `<strong>${text}</strong>`;
      }
      if (attributes.italic) {
        text = `<em>${text}</em>`;
      }
      if (attributes.underline) {
        text = `<u>${text}</u>`;
      }
      if (attributes.strike) {
        text = `<s>${text}</s>`;
      }
      if (attributes.header) {
        text = `<h${attributes.header}>${text}</h${attributes.header}>`;
      }
      if (attributes.list) {
        if (attributes.list === "ordered") {
          text = `<ol><li>${text}</li></ol>`;
        } else {
          text = `<ul><li>${text}</li></ul>`;
        }
      }
      if (attributes.blockquote) {
        text = `<blockquote>${text}</blockquote>`;
      }
      if (attributes["code-block"]) {
        text = `<pre><code>${text}</code></pre>`;
      }
      if (attributes.link) {
        text = `<a href="${attributes.link}">${text}</a>`;
      }
      if (attributes.image) {
        text = `<img src="${attributes.image}" alt="${text}"/>`;
      }
      if (attributes.video) {
        text = `<iframe src="${attributes.video}" frameborder="0" allowfullscreen>${text}</iframe>`;
      }

      html += text;
    } else if (op.insert.image) {
      html += `<img src="${op.insert.image}" alt=""/>`;
    } else if (op.insert.video) {
      html += `<iframe src="${op.insert.video}" frameborder="0" allowfullscreen></iframe>`;
    }
  });

  // Remove all occurrences of \n\n
  html = html.replace(/\n\n/g, "");

  return html;
}
export function removeAllNewlines(str) {
  // Replace all newline characters with an empty string
  return str.replace(/\n/g, "");
}
export function convertJsonToArray(jsonObj) {
  return Object.keys(jsonObj).map((key) => {
    return { name: key, value: jsonObj[key] };
  });
}

export function convertObjectToArray(objectVal) {
  return Object.entries(objectVal).map(([key, value]) => ({
    name: key,
    value,
  }));
}
export function convertArrayToObject(array) {
  return array.reduce((result, item) => {
    result[item?.name] = item?.value || "";
    return result;
  }, {});
}

export function calculateJsonSize(json) {
  // Convert the JSON object to a string
  const jsonString = JSON.stringify(json);

  // Calculate the size in bytes
  const sizeInBytes = new TextEncoder().encode(jsonString).length;

  return sizeInBytes;
}

export function removeAllHtmlTags(str) {
  // Regular expression to match all HTML tags
  const regex = /<[^>]*>/g;

  // Replace all HTML tags with an empty string
  return str.replace(regex, "");
}
export function getBase64Size(base64) {
  // Remove base64 prefix if it exists
  const base64String = base64.split(",")[1] || base64;

  // Calculate the length of the base64 string
  const length = base64String.length;

  // Calculate the size in bytes
  const sizeInBytes =
    (length * 3) / 4 -
    (base64String.endsWith("==") ? 2 : base64String.endsWith("=") ? 1 : 0);

  return sizeInBytes;
}
export function decodeJWT(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function removeLastNewline(str) {
  if (isNullOrEmpty(str)) return str;
  // Find the position of the last newline character
  const lastIndex = str.lastIndexOf("\n");

  // If a newline character is found, remove it
  if (lastIndex !== -1) {
    return str.slice(0, lastIndex) + str.slice(lastIndex + 1);
  }

  // If no newline character is found, return the original string
  return str;
}

export function removeFirstPWrapper(str) {
  // Regular expression to match the first opening <p> tag and the last closing </p> tag
  const regex = /^<p>([\s\S]*?)<\/p>$/;

  // Replace the matched <p> tags with the content inside them
  return str.replace(regex, "$1");
}

export function ensurePWrapper(str) {
  // Regular expression to check if the string starts with <p> and ends with </p>
  const regex = /^<p>[\s\S]*<\/p>$/;

  // If the string does not match the regex, wrap it with <p> tags
  if (!regex.test(str)) {
    return `<p>${str}</p>`;
  }

  // If the string already has <p> tags, return it as is
  return str;
}

export function distinctByFieldName(array, fieldName) {
  const unique = array?.reduce((result, item) => {
    if (isNullOrEmpty(item[fieldName])) return result;
    return result?.some((i) => i[fieldName] === item[fieldName])
      ? result
      : [...result, item];
  }, []);

  return unique;
}

export const getFilesBinary = (files) => {
  return Promise.allSettled(
    files?.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({ info: file, binary: reader.result });
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    })
  );
};

export const detectLink = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return str.match(urlRegex);
};
export const formatStringWithLinks = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return str.replace(urlRegex, '<a href="$1">$1</a>');
};

export const getFileTypeByMimeType = (mimeType) => {
  if (IMAGE_MIME_TYPES[mimeType]) {
    return "image";
  } else {
    return "file";
  }
};

export const truncateAroundKey = (content, key) => {
  if (!content) {
    return "";
  }

  const normalizedContent = normalizeString(content);
  const nfdContent = getNFDString(normalizedContent);
  const lowerCaseContent = content?.toLowerCase();
  const contentVariations = [
    content,
    normalizedContent?.toLowerCase(),
    nfdContent?.toLowerCase(),
    lowerCaseContent,
  ];

  const normalizedKey = normalizeString(key);
  const nfdKey = getNFDString(normalizedKey);
  const lowerCaseKey = key?.toLowerCase();
  const keyVariations = [
    key?.toLowerCase(),
    normalizedKey?.toLowerCase(),
    nfdKey?.toLowerCase(),
    lowerCaseKey,
  ];

  let keyIndex = -1;
  for (let contentVariant of contentVariations) {
    for (let keyVariant of keyVariations) {
      keyIndex = contentVariant.indexOf(keyVariant);
      if (keyIndex !== -1) break;
    }
    if (keyIndex !== -1) break;
  }

  if (keyIndex === -1) {
    return `${content.substring(0, 100)}...`;
  }

  const start = Math.max(0, keyIndex - 50);
  const end = Math.min(content.length, keyIndex + key.length + 50);

  let result = content.substring(start, end);

  if (start > 0) {
    result = "..." + result;
  }

  if (end < content.length) {
    result += "...";
  }

  return result;
};

export const getFileErrorMessageByCodeString = (code) => {
  switch (code) {
    case "file-too-large":
      return "error.fileTooLarge";
    case "file-invalid-type":
      return "error.invalidFileType";
    case "too-many-files":
      return "error.tooManyFiles";
    default:
      return "error.invalidFile";
  }
};

export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};
export const distinctBy = (array, callback) => {
  const map = new Map();
  const result = [];

  for (const item of array) {
    const key = callback(item);
    if (!map.has(key)) {
      map.set(key, true);
      result.push(item);
    }
  }

  return result;
};

export const normalizeString = (string) => {
  return string
    ?.replace(/[\u0300-\u036f]/g, "")
    ?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
    ?.trim();
};

export const getNFDString = (string) => {
  return string?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
};

export const safeJsonParse = (jsonString, defaultValue = null) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return defaultValue;
  }
};
export const convertToJsonString = (input) => {
  // This is a very naive approach and might not work for all cases.
  // It simply adds double quotes around any word characters (letters, digits, and underscores)
  // that come right before a colon, which is a common pattern for property names in JSON.
  const corrected = input.replace(/([a-zA-Z0-9_]+)\s*:/g, '"$1":');
  try {
    // Now, try parsing it to ensure it's valid JSON after the replacement
    JSON.parse(corrected);
    // If parsing succeeds, return the corrected string
    return corrected;
  } catch (error) {
    // If parsing fails, log an error or handle it as needed
    console.error("Failed to convert to JSON:", error);
    return null;
  }
};
export const isExpired = (expiredDate) => {
  const now = new Date();
  const expiryDate = new Date(expiredDate);
  return now > expiryDate;
};

export const getConversationDistinctId = (conversation) => {
  return `${conversation?.channel}-${conversation?.conversationId}`;
};

export const getHighestRole = (userRoles) => {
  if (userRoles?.length == 0) {
    return "";
  }
  let highestRole = null;
  let highestLevel = 0;

  userRoles?.forEach((role) => {
    const roleLevel = RoleLevel[role];
    if (roleLevel > highestLevel) {
      highestLevel = roleLevel;
      highestRole = role;
    }
  });

  return highestRole;
};

export const calculateExpiredDate = (message) => {
  const messageTime = new Date(message?.time).getTime(); // Convert message time to milliseconds
  const expiredDate = new Date(messageTime + MESSAGE_EXPIRED_TIME); // Add MESSAGE_EXPIRED_TIME to message time
  //024-06-27T08:18:13.155+00:00
  return expiredDate.toISOString(); // Convert expired date to local date string format with time
};

export const isValidUrl = (urlString) => {
  try {
    const url = new URL(urlString);
    return ["http:", "https:"].includes(url.protocol);
  } catch (_) {
    return false;
  }
};
export const convertArrayObjectIntoMap = (arrayObject) => {
  return arrayObject?.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});
};
