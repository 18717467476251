/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import AppSidebar from "./AppSidebar";
import { LeftOutlined } from "@ant-design/icons";
import AppContentView from "../../AppContentView";
import AppThemeSetting from "../../AppThemeSetting";
import AppFooter from "../components/AppFooter";
import clsx from "clsx";
import { FooterType } from "@crema/constants/AppEnums";
import { useLayoutContext } from "@crema/context/AppContextProvider/LayoutContextProvider";
import {
  StyledAppLayout,
  StyledAppLayoutMain,
  StyledMainScrollbar,
  StyledToggle,
} from "./index.styled";
import PropsTypes from "prop-types";
import { useUrlSearchParams } from "use-url-search-params";

const Default = ({ routesConfig, routes }) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const { footer, footerType } = useLayoutContext();
  const [params] = useUrlSearchParams();
  const { popout } = params;

  return popout ? (
    <div
      style={{
        position: "relative",
        width: "100vw",
        minHeight: "100dvh",
        height: "100dvh",
      }}
    >
      <StyledMainScrollbar>
        <AppContentView routes={routes} />
      </StyledMainScrollbar>
    </div>
  ) : (
    <StyledAppLayout
      className={clsx({
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}
    >
      <div style={{ position: "relative" }}>
        <AppSidebar isCollapsed={isCollapsed} routesConfig={routesConfig} />
        <StyledToggle
          onClick={() => setCollapsed(!isCollapsed)}
          className={clsx("md:visible invisible", { close: !isCollapsed })}
        >
          <LeftOutlined />
        </StyledToggle>
      </div>
      <StyledAppLayoutMain className="app-layout-main">
        <StyledMainScrollbar>
          <AppContentView routes={routes} />
          <AppFooter />
        </StyledMainScrollbar>
      </StyledAppLayoutMain>
      <AppThemeSetting />
    </StyledAppLayout>
  );
};

export default Default;
Default.propTypes = {
  routesConfig: PropsTypes.array.isRequired,
  routes: PropsTypes.object.isRequired,
};
