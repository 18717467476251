import React from "react";
import { HiUser } from "react-icons/hi";
import AppHeader from "@crema/components/AppLayout/Default/AppHeader";
import { useIntl } from "react-intl";
const HeaderPofile = () => {
  const { messages } = useIntl();
  return (
    <AppHeader>
      <div className="flex justify-between w-full h-[56px]">
        <div className="cursor-pointer flex items-center">
          <HiUser
            className="icon"
            color="#fa8c16"
            size="24px"
            style={{ marginRight: "4px" }}
          />
          <span className="text-lg font-bold text-primary">
            {messages["common.profile"]}
          </span>
        </div>
      </div>
    </AppHeader>
  );
};

export default HeaderPofile;
