import React, { createContext, useContext, useState } from "react";
import defaultConfig from "@crema/constants/defaultConfig";
import PropTypes from "prop-types";
import { APP_CONFIG } from "@crema/constants/AppConfig";

const LocaleContext = createContext();
const LocaleActionsContext = createContext();
export const useLocaleContext = () => useContext(LocaleContext);

export const useLocaleActionsContext = () => useContext(LocaleActionsContext);

const LocaleContextProvider = ({ children }) => {
  const initialLocale =
    JSON.parse(localStorage.getItem(APP_CONFIG.LANGUAGE_WEB)) ||
    defaultConfig.locale;
  const [locale, updateLocale] = useState(initialLocale);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        rtlLocale: defaultConfig.rtlLocale,
      }}
    >
      <LocaleActionsContext.Provider
        value={{
          updateLocale,
        }}
      >
        {children}
      </LocaleActionsContext.Provider>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
