import React from "react";
import PropTypes from "prop-types";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import styled from "styled-components";

export const StyledScrollbar = styled(SimpleBarReact)`
  position: relative;
  width: 100%;
  height: 100%;

  & .simplebar-offset,
  & .simplebar-content-wrapper,
  & .simplebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

// eslint-disable-next-line no-unused-vars
const AppScrollbar = ({ children, className, ...others }) => {
  return (
    <StyledScrollbar {...others} className={className}>
      {children}
    </StyledScrollbar>
  );
};

export default AppScrollbar;

AppScrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  scrollToTop: PropTypes.bool,
};
