import { createGlobalStyle } from "styled-components";
import { rgba, darken } from "polished";

export const GlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    font-family: ${({ theme }) => theme.font.family};
    color: ${({ theme }) => theme.palette.text.primary};
  }

  canvas {
    display: block;
    height: 100%;
  }

  body {
    color: ${({ theme }) => theme.palette.text.primary};
    line-height: 1.35 !important;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};

    @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm + 320}px ) {
      font-size: ${({ theme }) => theme.font.size.lg};
    }
  }

  .link {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ul  {
    list-style: disc !important ;
    margin: 0;
    padding: 0;
  }
  ol {
    list-style: decimal !important ;
    margin: 0;
    padding: 0;
  }

  p {
  margin-top: 0;
  margin-bottom: 0 !important;
  }
  

  img {
    max-width: 100%;
    height: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 500;
    font-family: ${({ theme }) => theme.font.family};
  }

  h1 {
    font-size: 22px;
    font-weight: 600;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  .pointer {
    cursor: pointer;
  }
  .context-menu {
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background-color: white;
    position: fixed;
    z-index: 1000;
    width:fit-content;
  }

  .context-menu--item{
    padding: 10px 20px;
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .card-hover {
    transition: all 0.3s ease;
    transform: scale(1);

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, .45);
      transform: scale(1.05);
    }
  }

  .disabled {
    cursor: not-allowed !important;
    opacity: 0.7;
    color: ${({ theme }) => theme.palette.text.disabled};
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.palette.primary.main} !important;
  }

  .ant-btn-primary {
    background: ${({ theme }) => theme.palette.primary.main} !important;
    border-color: ${({ theme }) => theme.palette.primary.main} !important;

    &:hover,
    &:focus {
      background: ${({ theme }) =>
        darken(0.08, theme.palette.primary.main)} !important;
      border-color: ${({ theme }) =>
        darken(0.08, theme.palette.primary.main)} !important;
    }
  }

  .ant-btn-background-ghost.ant-btn-primary {
    color: ${({ theme }) => theme.palette.primary.main} !important;
    background: transparent !important;
    border-color: ${({ theme }) => theme.palette.primary.main} !important;

    &:hover,
    &:focus {
      color: ${({ theme }) =>
        darken(0.08, theme.palette.primary.main)} !important;
      background: transparent;
      border-color: ${({ theme }) =>
        darken(0.08, theme.palette.primary.main)} !important;
    }
  }

  .ant-spin {
    color: ${({ theme }) => theme.palette.primary.main} !important;
  }

  .ant-list-item {
    padding: 6px 0 !important;
    justify-content: flex-start !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }
  .ant-pagination-options{
    display: none !important;
  }

  .ant-spin-dot i,
  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.palette.primary.main} !important;
  }


  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.palette.primary.main} !important;
  }

  .ant-table {
    background: transparent !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }

  .ant-table-wrapper .ant-table {
    background: transparent !important;
    height: 93% !important;
    max-height: 93% !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }
  
  .ant-table-body {
  max-height: 90% !important;
  overflow-x: auto !important; // Ensure horizontal scrollbar can appear
  overflow-y: auto !important; // Keep vertical scrollbar hidden
}
  /* Hide the scrollbar if there's no overflow */
  .ant-table-body::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

  .ant-table-container{
    height: 99% !important;
  }
  .ant-pagination{
    margin: unset !important;
    margin-right: 16px !important;
    margin-bottom: 6px !important;
  }

  .ant-table-wrapper{
    padding: unset !important;
  }


  .ant-select-selection-item {
  min-height: 32px !important;
  align-items: center !important;
}

.custom-table-actions-column {
  padding: 0 6px !important;
  width: fit-content !important;
}


  .text-lowercase {
    text-transform: lowercase;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .text-left {
    text-align: left;

    [dir=rtl] & {
      text-align: right;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;

    [dir=rtl] & {
      text-align: left;
    }
  }

  .background-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .cr-btn {
    box-sizing: border-box;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
  }

  .cr-btn-circle {
    background-color: transparent;
    border-color: transparent;
    color: ${({ theme }) => theme.palette.gray[500]};
    box-shadow: none;

    &:hover, &:focus {
      background-color: ${({ theme }) => theme.palette.tooltipBg + "04"};
      border-color: ${({ theme }) => theme.palette.tooltipBg + "04"};
      color: ${({ theme }) => theme.palette.text.primary};
    }

    & .anticon {
      font-size: 20px;
    }
  }

  .btn-secondary {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-color: ${({ theme }) => theme.palette.borderColor};
    color: ${({ theme }) => theme.palette.white};

    &:hover, &:focus {
      background-color: ${({ theme }) =>
        darken(0.25, theme.palette.secondary.main)} !important;
      border-color: ${({ theme }) =>
        darken(0.25, theme.palette.secondary.main)} !important;
      color: ${({ theme }) => theme.palette.white} !important;
    }
  }

  .btn-light-blue {
    background-color: ${({ theme }) => rgba(theme.palette.primary.main, 0.1)};
    color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => rgba(theme.palette.primary.main, 0.1)};

    &:hover, &:focus {
      background-color: ${({ theme }) => rgba(theme.palette.primary.main, 0.2)};
      color: ${({ theme }) => theme.palette.primary.main};
      border-color: ${({ theme }) => rgba(theme.palette.primary.main, 0.2)};
    }
  }

  .btn-secondary-outline {
    background-color: transparent;
    border-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.main};
    box-shadow: none;

    &:hover, &:focus {
      background-color: transparent;
      border-color: darken(0.15, ${({ theme }) =>
        theme.palette.secondary.main});
      color: darken(0.15, ${({ theme }) => theme.palette.secondary.main});
    }
  }

  .btn-primary-outline {
    background-color: transparent;
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: none;

    &:hover, &:focus {
      background-color: transparent;
      border-color: darken(0.15, ${({ theme }) => theme.palette.primary.main});
      color: darken(0.15, ${({ theme }) => theme.palette.primary.main});
    }
  }

  .btn-white-outline {
    background-color: transparent;
    border-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.white};
    box-shadow: none;

    &:hover, &:focus {
      background-color: transparent;
      border-color: ${({ theme }) => theme.palette.white};
      color: ${({ theme }) => theme.palette.white};
    }
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .dot {
    height: 12px;
    width: 12px;
    display: block;
    border-radius: ${({ theme }) => theme.sizes.borderRadius.circle};
  }

  .close-btn {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.text.primary};
    box-shadow: none;
    border: 0 none;
    padding: 0;
    height: auto;

    &:hover, &:focus {
      border: 0 none;
    }

    & .anticon {
      font-size: 20px;
    }
  }

  .page-title {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-bottom: 16px;
    font-size: ${({ theme }) => theme.font.size.lg};
    display: inline-block;
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    width: 12px;
    height: 12px;
  }

  .ant-modal {
    max-width: 90%;
    max-height:90vh
  }

  .text-secondary {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .text-green {
    //color: @green-6;
  }

  .z-index-20 {
    z-index: 20;
  }

  .ant-picker {
    //padding-top: @input-padding-vertical-base;
    //padding-bottom: @input-padding-vertical-base;
  }

  .ant-pagination {
    & li {
      margin-bottom: 4px;
      margin-top: 4px;
      overflow: hidden;
    }

    &.mini {
      & .ant-pagination-item,
      & .ant-pagination-prev,
      & .ant-pagination-next {
        margin-bottom: 4px;
        margin-top: 4px;
      }
    }
  }

  .ant-drawer,
  .ant-modal-wrap,
  .ant-modal-mask,
  .ant-picker-dropdown,
  .ant-select-dropdown,
  .ant-message {
    z-index: 1051;
  }

  .ant-drawer-close {
    color: ${({ theme }) => theme.palette.text.secondary};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    top: 11px;
    right: 6px;
    padding: 5px;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.palette.background.default};
      color: ${({ theme }) => theme.palette.text.primary};

    }
  }

  // Ant Tooltip
  .ant-tooltip-inner {
    & a {
      color: ${({ theme }) => theme.palette.white};
      display: flex;
      align-items: center;

      & .ant-menu-item-icon {
        margin-right: 6px;

        & .anticon, & svg {
          display: block;
        }
      }
    }
  }


  .item-hover {
    transition: all .2s ease;
    transform: scale(1);

    &:hover {
      background-color: ${({ theme }) => rgba(theme.palette.primary.main, 0.1)};
      transform: translateY(-2px);
      box-shadow: 0 3px 10px 0 ${({ theme }) =>
        rgba(theme.palette.primary.main, 0.1)};
    }
  }

  .unread-message {
    transition: all .2s ease;
    transform: scale(1);
    font-weight: 900 !important;
    line-height: 1.5 !important;
    transform: translateY(-2px);
    box-shadow: 0 3px 10px 0 ${({ theme }) =>
      rgba(theme.palette.gray[200], 0.1)};
  }

  .negative-message {
    transition: all .2s ease;
    background-color: ${({ theme }) =>
      rgba(theme.palette.red[6], 0.2)} !important;
    box-shadow: 0 3px 10px 0 ${({ theme }) =>
      rgba(theme.palette.red[6], 0.2)} !important;;
    &:hover {
    transform: scale(1);
      transform: translateY(-2px);
    }
  }

  .cr-dropdown-link {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.sizes.borderRadius.circle};
    color: ${({ theme }) => rgba(theme.palette.tooltipBg, 0.84)};
    font-size: 20px;

    &:hover {
      background-color: ${({ theme }) => rgba(theme.palette.tooltipBg, 0.1)};
    }
  }

  .slick-slider-global .slick-dots li {
    width: 12px;
    height: 12px;
    margin: 0 2px;
    vertical-align: middle;
  }

  .slick-slider-global .slick-dots li button {
    width: 12px;
    height: 12px;
    padding: 0;
  }

  .slick-slider-global .slick-dots li.slick-active button:before {
    color: #825a44;
    opacity: .75;
  }

  .slick-slider-global .slick-dots li button:before {
    width: 12px;
    height: 12px;
    font-size: 10px;
    line-height: 12px;
    color: #be8658;
  }


.hide-ant-form-item-explain-error .ant-form-item-explain-error {
  display: none;
} 

.ant-form-item{
  margin-bottom: 12px;
}

.ant-form-item-label{
  padding: 0 !important;
  font-weight: 700;
}
  .ant-card {
    border-radius: 16px;
    line-height: 1.35;
  }


  .ant-layout-content{
    height:100% !important;
  }

  .ant-btn, .ant-btn > .anticon {
    line-height: 1;
  }

  .ant-picker,
  .ant-picker-calendar-header,
  .ant-picker-panel,
  .ant-picker-body,
  .ant-picker-content,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-radio-input,
  .ant-input,
  .ant-picker-cell-in-view,
  .ant-input-affix-wrapper,
  .ant-select-dropdown,
  .ant-drawer-content,
  .ant-dropdown-menu,
  .ant-list-item-meta-description,
  .ant-input-group-addon > .ant-btn-icon-only,
 {
    background: ${({ theme }) => theme.palette.background.paper} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.primary} !important;

  }

  .ant-input::placeholder {
    color: ${({ theme }) => theme.palette.text.secondary} !important;
  }

  .ant-dropdown-trigger {
    background: transparent;
    ${"" /*color: ${({ theme }) => theme.palette.text.primary} !important;*/}
  }

  .ant-progress-text {
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }

  .anticon {
    background: transparent;
  }

  .ant-modal-content {
    background: ${({ theme }) => theme.palette.background.paper} !important;

    & .ant-modal-header {
      background: ${({ theme }) => theme.palette.background.paper} !important;

      & .ant-modal-title {
        color: ${({ theme }) => theme.palette.text.primary} !important;
      }
    }

    & .ant-modal-close-icon {
      color: ${({ theme }) => theme.palette.text.primary} !important;
    }
  }

  .ant-card {
    background: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
    overflow: hidden;
    border-radius: ${({ theme }) => theme.cardRadius};
  }

  .ant-radio-wrapper,
  .ant-list-item-meta-title,
  .ant-tabs-tab .ant-tabs-tab-btn,
  .ant-picker-content th,
  .ant-table-thead .ant-table-cell {
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .ant-select-arrow,
  .ant-picker-cell,
  .ant-list-item-meta-description {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .ant-checkbox-input {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .rnc__notification-container--top-right {
    right: 320px !important;
  }

  .rnc__notification-container--bottom-center,
  .rnc__notification-container--bottom-left {
    bottom: 200px !important;
  }

  .rnc__notification-container--bottom-right {
    bottom: 200px !important;
    right: 320px !important;
  }

  .boxedLayout {
    @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.xl + 80}px) {
      max-width: 1260px;
      margin-left: auto;
      margin-right: auto;

      #root {
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
      }

      & .appMainFixedFooter {
        & .app-main-footer {
          max-width: 1260px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      & .mini-sidebar-toggle.ant-layout-sider,
      & .mini-sidebar.ant-layout-sider,
      & .userMiniHeader-sidebar.ant-layout-sider {
        position: absolute;
      }

      & .app-header-mini-sidebar,
      & .app-header-mini,
      & .app-userMiniHeader {
        position: absolute;
        width: 100% !important;
      }

      & .mini-sidebar-toggle.ant-layout-sider-collapsed + .app-layout-mini-sidebar-main .app-header-mini-sidebar,
      & .mini-sidebar.ant-layout-sider-collapsed + .app-layout-mini-main .app-header-mini,
      & .userMiniHeader-sidebar.ant-layout-sider-collapsed + .app-layout-userMiniHeader-main .app-userMiniHeader {
        width: 100% !important;
      }

      & .app-layout-mini-sidebar,
      & .app-layout-mini,
      & .app-layout-userMiniHeader {
        padding-top: 0;
      }

      & .app-layout-mini-sidebar-main,
      & .app-layout-mini-main,
      & .app-layout-userMiniHeader-main {
        padding-top: 71px;
      }

      & .coin-stats-content h3 {
        font-size: ${({ theme }) => theme.font.size.lg};
      }

      & .coin-stats-content span {
        font-size: ${({ theme }) => theme.font.size.sm};
      }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
      max-width: 1460px;

      & .appMainFixedFooter {
        & .app-main-footer {
          max-width: 1460px;
        }
      }
    }
  }
  .ant-modal-body{
    max-height: 75vh;
    overflow-y:auto !important;
  }

  //Framed Layout
  .framedLayout {
    @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.xl + 80}px) {
      padding: ${({ theme }) => theme.sizes.framed.base};
      background-color: ${({ theme }) => theme.palette.secondary.main};

      #root {
        height: calc(100dvh - 2 * ${({ theme }) => theme.sizes.framed.base});
        overflow: hidden;
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
      }

      &.framedHorLayout,
      &.framedHorDarkLayout,
      &.framedHorHeaderFixedLayout {

        #root {
          overflow-y: auto;
        }
      }

      & .app-sidebar-scrollbar,
      & .main-scrollbar,
      & .app-mini-sidebar-scrollbar,
      & .main-mini-sidebar-scrollbar,
      & .main-mini-scrollbar,
      & .standard-main-scrollbar,
      & .userHeader-main-scrollbar,
      & .main-userMiniHeader-scrollbar,
      & .drawerLayout-main-scrollbar,
      & .app-BitBucket-sidebar-scrollbar {
        max-height: calc(100dvh - (71px + 2 * ${({ theme }) =>
          theme.sizes.framed.base}));
      }

      & .app-standard-sidebar-scrollbar {
        max-height: calc(100dvh - (140px + 2 * ${({ theme }) =>
          theme.sizes.framed.base}));
      }

      & .app-userHeader-sidebar-scrollbar,
      & .app-sidebar-userMiniHeader-scrollbar,
      & .bitBucket-main-scrollbar,
      & .bucket-minibar {
        max-height: calc(100dvh - (2 * ${({ theme }) =>
          theme.sizes.framed.base}));
      }

      & .app-layout {
        height: calc(100dvh - 2 * ${({ theme }) => theme.sizes.framed.base});
        min-height: 10px;
      }

      & .auth {
        min-height: 10px;
        height: 100%;
      }

      & .main-auth-scrollbar {
        min-height: 10px;
      }

      & .apps-container {
        height: calc(100dvh - (153px + 2 * ${({ theme }) =>
          theme.sizes.framed.base}));
      }

      & .app-layout-mini-sidebar,
      & .app-layout-mini,
      & .app-layout-hor,
      & .app-layout-hor-header-fixed {
        min-height: 100%;
      }

      & .mini-sidebar-toggle.ant-layout-sider,
      & .mini-sidebar.ant-layout-sider,
      & .userMiniHeader-sidebar.ant-layout-sider {
        position: absolute;
      }

      & .app-header-mini-sidebar,
      & .app-header-mini,
      & .app-userMiniHeader {
        position: absolute;
        width: 100% !important;
      }

      & .mini-sidebar-toggle.ant-layout-sider-collapsed + .app-layout-mini-sidebar-main .app-header-mini-sidebar,
      & .mini-sidebar.ant-layout-sider-collapsed + .app-layout-mini-main .app-header-mini,
      & .userMiniHeader-sidebar.ant-layout-sider-collapsed + .app-layout-userMiniHeader-main .app-userMiniHeader {
        width: 100% !important;
      }

      & .app-layout-mini-sidebar,
      & .app-layout-mini,
      & .app-layout-userMiniHeader {
        padding-top: 0;
      }

      & .app-layout-mini-sidebar-main,
      & .app-layout-mini-main,
      & .app-layout-userMiniHeader-main {
        padding-top: 71px;
      }
    }
  }
  .ant-modal-content {
  padding: unset !important;
  & .ant-modal-body {
    padding: 12px 16px !important;
  }
  & .ant-modal-footer {
    margin-top: 4px;
    padding: 0 20px 24px 20px !important;
  }
}
.custom-column-class{
  &--100{
    max-width:100px !important;
  }
  &--120{
    max-width:120px !important;
  }
  &--150{
    max-width:150px !important;

  }  
  &--180{
    max-width:180px !important;

  }
  &--200{
    max-width:200px !important;

  }
  &--250{
    max-width:250px !important;

  }
  &--300{
    max-width:300px !important;

  }
}
.ant-message {
    z-index: 100000 !important; 
}
.custom-blockquote {
  border-left: 4px solid #ccc;
  padding: 10px;
  color: #666;
  background-color: #f9f9f9;
}
.custom-code-block {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

.loader-icon .path {
  stroke: var(--primary-branding-color) !important;
}

div[data-testid="virtuoso-item-list"]{
  margin-top: unset !important;
}

.app-header-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  & .logo-section {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  & .header-label {
    font-size: 20px;
    font-weight: bold;
    color: #fa8c16;
  }

  & .end-section {
    display: flex;
    gap: 8px;
  }
}

.table-container {
  padding: 6px;
  height: calc(100% - 75px);
}
/* For Webkit-based browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 4px;  /* Adjust the width to make it thinner */
    height: 4px; /* Adjust the height to make it thinner */
}

::-webkit-scrollbar-track {
    background: transparent; /* Make the scrollbar track transparent */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* Scrollbar color with some transparency */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
    border: 2px solid transparent; /* Optional: adds space around the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4); /* Slightly less transparent on hover */
}

/* For Firefox */
html {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent; /* Scrollbar color and track color */
}

/* Optional: For scrollbar customization on specific elements */
.your-element-class {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

/* Hide the scrollbar while allowing scroll */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    scrollbar-width: none; /* For Firefox */
}
 ${"" /* follow the ql editor style*/}
.message-item{
  pre{
    background-color: #23241f;
    color:#f8f8f2;
    overflow:visible;
    white-space: pre-wrap;
      margin-bottom: 5px;
      margin-top: 5px;
      padding: 5px 10px;
  }
  blockquote{
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    padding-left:16px;
    margin-top: 5px;
    padding-left: 16px;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
}
.ant-table-column-sort{
  background: transparent !important;
}

.ant-divider{
  &:before {
    display: none !important;
  }
}
.ant-divider-inner-text{
  padding-inline-start: 0 !important
}

.ant-divider-with-text {
  margin: 0 0 0 0 !important;
}

.divider-header-title{
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase; /* Make the title text uppercase */
  padding-bottom: 8px;   
}
.hint-text {
  font-weight:bold;
  font-ize: 16px;
  font-style: italic;
}
#loadingOverlay {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* High z-index to ensure it stays on top */
    display: none; /* Hidden by default */
}

.minutes-input input {
  padding-right: 70px; /* Make space for the suffix */
}

.minutes-input {
  position: relative;
}

.minutes-input::after {
  content: 'minutes';
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.45); 
  pointer-events: none;
}

.question-max-length-input input {
  padding-right: 70px; /* Make space for the suffix */
}

.question-max-length-input {
  position: relative;
}

.question-max-length-input::after {
  content: 'characters';
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.45); 
  pointer-events: none;
}
`;
