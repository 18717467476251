import Default from "./Default";
import Standard from "./Standard";
import { NavStyle } from "@crema/constants/AppEnums";
//import BitBucket from "./BitBucket";
//import HorDefault from "./HorDefault";
//import MiniSidebar from "./MiniSidebar";
//import DrawerLayout from "./DrawerLayout";
// import HorLightNav from './HorLightNav';
//import HorDarkLayout from "./HorDarkLayout";
//import HeaderUserLayout from "./UserHeader";
//import HeaderUserMiniLayout from "./UserMiniHeader";
//import MiniSidebarToggle from "./MiniSidebarToggle";
//import HorHeaderFixed from "./HorHeaderFixed";

const Layouts = {
  [NavStyle.STANDARD]: Standard,
  [NavStyle.DEFAULT]: Default,
};
export default Layouts;
