import {
  getDataApi,
  postDataApi,
  downloadImageApi,
} from "@crema/hooks/APIHooks";
import { userFetchingObject } from "@crema/constants/AppConst";
export const updateUserProfile = (params, infoViewContext) => {
  const url = userFetchingObject.update;
  return postDataApi(url, infoViewContext, params, true);
};

export const changePassword = (params, infoViewContext) => {
  const url = userFetchingObject.changePassword;
  return postDataApi(url, infoViewContext, params);
};

export const updateUserPhoto = (params, infoViewContext) => {
  const url = userFetchingObject.uploadProfilePhoto;
  return postDataApi(url, infoViewContext, params);
};

export const downloadUserPhotoByUserId = (userId) => {
  const url = userFetchingObject.downloadProfilePhotoByUserId + userId;
  return getDataApi(url, {}, {}, false, {
    "Content-Type": "image/jpeg",
  });
};

export const downloadUserPhoto = (identifier) => {
  const url = userFetchingObject.downloadProfilePhoto + identifier;
  return getDataApi(url);
};

export const downloadUserPhotoResponseTypeBlob = (id) => {
  const url =
    userFetchingObject.downloadProfilePhotoByUserId +
    id +
    "&time=" +
    new Date().getTime(); // avoid caching the same endpoint

  return downloadImageApi(url);
};
