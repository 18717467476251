import React from "react";
import { RoutePermittedRole } from "@crema/constants/AppConst";
const Report = React.lazy(() => import("modules/apps/Report"));
const ConversationPopout = React.lazy(() =>
  import(
    "modules/apps/Conversation/modules/ConversationPopout/ConversationPopout"
  ),
);
const KnowledgeBase = React.lazy(() =>
  import("../../../modules/apps/KnowledgeBase"),
);
const Conversations = React.lazy(() =>
  import("../../../modules/apps/Conversation"),
);
const AiGroupManagement = React.lazy(() =>
  import("../../../modules/apps/AiGroupManagement"),
);
const FunctionDefs = React.lazy(() =>
  import("../../../modules/apps/FunctionDefs"),
);
const ConversationForTesting = React.lazy(() =>
  import(
    "shared/modules/ConversationPopoutForTesting/ConversationPopoutForTesting"
  ),
);
const Group = React.lazy(() => import("../../../modules/apps/Group"));
const AiAgent = React.lazy(() => import("../../../modules/apps/AiAgent"));
const Users = React.lazy(() => import("../../../modules/apps/Users"));
const Rules = React.lazy(() => import("../../../modules/apps/Rules"));
const Bots = React.lazy(() => import("../../../modules/apps/Bots"));
const DashboardDesigner = React.lazy(() =>
  import("../../../modules/dashboards/DashboardDesigner"),
);
const DashboardsAndReports = React.lazy(() =>
  import("../../../modules/dashboards/DashboardsAndReports"),
);

export const appsConfig = [
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: ["/apps/conversations"],
    element: <Conversations />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: ["/apps/conversations/:id"],
    element: <ConversationPopout />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_AGENT,
    path: ["/apps/conversations/testing/:groupCode/:groupName"],
    element: <ConversationForTesting />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_MANAGER,
    path: ["/apps/groups"],
    element: <Group />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/ai-agents"],
    element: <AiAgent />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/agents"],
    element: <Users />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/ai-flows"],
    element: <AiGroupManagement />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/rules"],
    element: <Rules />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/bots"],
    element: <Bots />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/function-defs"],
    element: <FunctionDefs />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/knowledge-base"],
    element: <KnowledgeBase />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/dashboard-designer"],
    element: <DashboardDesigner />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/messages-log"],
    element: <Report />,
  },
  {
    permittedRole: RoutePermittedRole.ROLE_XCHATBOT_ADMIN,
    path: ["/apps/dashboards"],
    element: <DashboardsAndReports />,
  },
];
