import AppScrollbar from "../../AppScrollbar";
import { Input, Layout } from "antd";
import styled from "styled-components";
import MainSidebar from "../components/MainSidebar";

const { Header } = Layout;
const { Search } = Input;

export const StyledAppHeader = styled(Header)`
  padding: 15px 6px;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary}!important;
  background-color: ${({ theme }) => theme.palette.background.paper}!important;
  line-height: 1;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  /* border-bottom: 1px solid ${({ theme }) =>
    theme.palette.borderColor}!important; */
  transition: all 0.1s linear;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;

  &.ant-layout-header {
    flex: 0 0;
    max-height: 100% !important;
    box-shadow: unset !important;
    border-bottom: 1px solid #e5e5e5;
    height: 100% !important;
  }

  & .trigger {
    font-size: 20px;
    margin-right: 10px;
    padding: 5.5px;
    color: ${({ theme }) => theme.palette.text.primary};

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 10px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      margin-right: 20px;

      [dir="rtl"] & {
        margin-right: 0;
        margin-left: 20px;
      }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
      ${"" /*display: none;*/}
    }

    & svg {
      display: block;
    }
  }
`;

export const StyledHeaderSearch = styled(Search)`
  position: relative;
  max-width: 140px;
  min-height: 36px;
  margin-left: auto;
  margin-right: 10px;

  [dir="rtl"] & {
    margin-left: 10px;
    margin-right: auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-right: 20px;
    max-width: 165px;

    [dir="rtl"] & {
      margin-right: auto;
      margin-left: 20px;
    }
  }

  & .ant-input-wrapper {
    top: 50%;
    right: 0;
    z-index: 1;
    position: absolute;
    transform: translateY(-50%);

    [dir="rtl"] & {
      right: auto;
      left: 0;
    }
  }

  & .ant-input {
    padding: 8px 14px;
    height: 36px;
    transition: all 0.2s ease;
    width: 104px;

    &:focus {
      width: 135px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
      width: 129px;

      &:focus {
        width: 165px;
      }
    }
  }

  & .ant-input-search-button {
    height: 36px;
    width: 36px;
    box-shadow: none;

    & .anticon svg {
      display: block;
    }
  }

  &.ant-input-search-rtl
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: ${({ theme }) => theme.sizes.borderRadius.base} 0 0
      ${({ theme }) => theme.sizes.borderRadius.base};
  }
`;

export const StyledAppHeaderSectionDesk = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: flex;
    align-items: center;
  }
`;

export const StyledAppHeaderSectionMobile = styled.div`
  display: block;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    ${"" /*display: none;*/}
  }
`;

export const StyledAppMainSidebar = styled(MainSidebar)`
  &.ant-layout-sider {
    flex: 0 0 auto !important;
    min-width: 250px !important;
    width: fit-content !important;
    transition: all 0.1s linear;
    border-right: 1px solid #9f9f9f1f;
    background-color: inherit;

    ${
      "" /*@media screen and (min-width: 1200px) and (max-width: 1300px) {
      width: 80px !important;
      min-width: 80px !important;
    }

    @media screen and (max-width: 768px) {
      min-width: 65px !important;
      max-width: 65px !important;
      width: 65px !important;
    }*/
    }

    &.ant-layout-sider-collapsed {
      width: 68px !important;
      min-width: 68px !important;
      max-width: 68px !important;
    }

    .ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
      display: none;
    }
  }

  &.collapsed {
    flex: 1 1 auto !important;
    max-width: 68px !important;
    width: 68px;
    min-width: 68px !important;
  }

  &.ant-layout-sider-dark {
    //background-color: @sidebar-dark-bg-color;
    background-color: inherit;
  }

  & .ant-layout-sider-trigger {
    display: none;
  }

  &.ant-layout-sider-has-trigger {
    padding-bottom: 0;
  }

  // Sidebar Collapsed
  &.ant-layout-sider-collapsed {
    width: 70px !important;
    transition: all 200ms linear;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      /* width: 65px !important; */
    }

    & + .app-layout-main {
      width: 100% !important; // style responsive here
      transition: all 200ms linear;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        width: calc(100% - 4rem) !important;
      }
    }

    & .cr-user-info {
      padding: 15px 12px;
      height: 100% !important;
    }

    & .cr-user-info-content {
      opacity: 0;
      /* visibility: hidden; */
      width: 0;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;

      [dir="rtl"] & {
        margin-right: 0;
      }
    }

    & .ant-menu-item-group-title {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      padding: 0;
    }
  }

  & .cr-user-info {
    border-bottom: 1px solid ${({ theme }) => theme.palette.borderColor};
  }

  &.sidebar-img-background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: inherit;
      z-index: 1;
      opacity: 0.5;
    }

    & > div {
      position: relative;
      z-index: 3;
    }

    & .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
      background-color: transparent;
      color: inherit;
    }

    & .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
    & .app-main-sidebar-menu .ant-menu-item a {
      color: inherit;
    }

    & .ant-menu {
      background-color: transparent;
    }
  }
`;

export const StyledAppSidebarScrollbar = styled(AppScrollbar)`
  height: calc(100dvh - 71px);

  .appMainFixedFooter & {
    height: calc(100dvh - 102px);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: calc(100dvh - 71px);

    .appMainFixedFooter & {
      height: calc(100dvh - 116px);
    }
  }
`;

export const StyledAppLayout = styled(Layout)`
  min-height: 100dvh;
  position: relative;
  background: ${({ theme }) => theme.palette.background.default};
  overflow-x: hidden;

  &.appMainFixedFooter {
    padding-bottom: 46px;

    & .app-main-footer {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
    }
  }

  & .ant-layout {
    background: none;
  }
  &.ant-layout-sider-zero-width-trigger {
    display: none;
  }
`;

export const StyledMainScrollbar = styled(AppScrollbar)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100% !important;
  max-height: 100dvh;

  .appMainFixedFooter & {
    max-height: calc(100dvh - 104px);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    .appMainFixedFooter & {
      max-height: calc(100dvh - 118px);
    }
  }
`;

export const StyledAppLayoutMain = styled(Layout)`
  transition: all 0.1s linear;
  width: calc(100% - 250px) !important;
  position: relative;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.palette.background.paper};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    flex-shrink: inherit;
  }
`;

export const StyledToggle = styled.span`
  position: absolute;
  top: 50%;
  right: -17px;
  transform: translateY(-50%) rotate(180deg);
  height: 32px;
  width: 32px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.palette.orange[5]};
  /* color: #ccc; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  z-index: 999;

  &.close {
    transition: 0.25s ease-in-out;
    transform: translateY(-50%) rotate(0);
  }

  .anticon {
    color: ${({ theme }) => theme.palette.orange[5]};
    font-size: 18px;
  }
`;
