import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input } from "antd";
import PropTypes from "prop-types";
import AppRowContainer from "@crema/components/AppRowContainer";
import { useDropzone } from "react-dropzone";
//import { getImageObjectUrl } from "@crema/helpers/Common";
import {
  StyledInfoUpload,
  StyledInfoUploadAvatar,
  StyledInfoUploadBtnView,
  StyledInfoUploadContent,
} from "./index.styled";
import { StyledUserProfileGroupBtn } from "../index.styled";
import { USER_DEFAULT_AVATAR_URL } from "@crema/constants/AppConst";
import { useIntl } from "react-intl";
import AppPageMeta from "@crema/components/AppPageMeta";

const PersonalInfo = ({ form, user, onFormSubmit, onPhotoChange }) => {
  const { messages } = useIntl();
  const [userImage, setUserImage] = useState("/assets/images/placeholder.jpg");
  const handleFormSubmit = () => {
    const values = form.getFieldsValue();
    onFormSubmit(values);
  };

  useEffect(() => {
    setUserImage(user?.moreInfo?.photoURL || USER_DEFAULT_AVATAR_URL);
  }, [user]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        let photoUrl = URL.createObjectURL(acceptedFiles[0]);
        setUserImage(photoUrl);
        onPhotoChange(acceptedFiles[0], photoUrl);
      }
    },
  });

  return (
    <>
      <AppPageMeta title={messages["common.profile"]} />
      <Form
        form={form}
        labelAlign="left"
        initialValues={{
          ...user,
          userImage: user?.moreInfo?.photoURL
            ? user?.moreInfo?.photoURL
            : "/assets/images/placeholder.jpg",
        }}
      >
        <StyledInfoUpload>
          <StyledInfoUploadAvatar src={userImage} />

          <StyledInfoUploadContent>
            <StyledInfoUploadBtnView>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <label htmlFor="icon-button-file">
                  <Button type="primary">
                    {messages["common.upload.label"]}
                  </Button>
                </label>
              </div>
            </StyledInfoUploadBtnView>
            <p>{messages["document.description"]}</p>
          </StyledInfoUploadContent>
        </StyledInfoUpload>
        <AppRowContainer gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label={messages["common.fullName"]}
              name="fullName"
              labelCol={{ span: 8 }}
              rules={[
                { required: true, message: messages["validation.required"] },
              ]}
            >
              <Input placeholder={messages["common.input.placeholder"]} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={messages["common.userName"]}
              labelCol={{ span: 8 }}
              name="username"
              rules={[
                { required: true, message: messages["validation.required"] },
              ]}
            >
              <Input
                placeholder={messages["common.input.placeholder"]}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={messages["common.contactEmail"]}
              labelCol={{ span: 8 }}
              name="contactEmail"
              rules={[
                {
                  required: true,
                  message: messages["validation.required"],
                },
              ]}
            >
              <Input
                type="text"
                placeholder={messages["common.input.placeholder"]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={messages["common.phoneNumber"]}
              labelCol={{ span: 8 }}
              name="phoneNumber"
              rules={[
                {
                  pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                  message: messages["validation.phoneNumberValid"],
                },
              ]}
            >
              <Input
                type="tel"
                placeholder={messages["common.input.placeholder"]}
              />
            </Form.Item>
          </Col>
        </AppRowContainer>
      </Form>
      <StyledUserProfileGroupBtn
        shouldUpdate
        className="user-profile-group-btn"
      >
        <Button type="primary" onClick={handleFormSubmit}>
          {messages["common.save"]}
        </Button>
      </StyledUserProfileGroupBtn>
    </>
  );
};
PersonalInfo.propTypes = {
  form: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func,
  onPhotoChange: PropTypes.func,
  user: PropTypes.object.isRequired,
};
export default PersonalInfo;
